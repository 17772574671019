import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import {
	IAddress,
	ICommunication,
	ICommunicationOptions,
	IDataQuery,
	IDateRange,
	IDateRangeV2,
	IFormGroup,
} from './interfaces';
import {
	addDays,
	addWeeks,
	endOfDay,
	endOfMonth,
	endOfQuarter,
	endOfWeek,
	endOfYear,
	format,
	getDate,
	parseISO,
	set,
	setDay,
	startOfMonth,
	startOfQuarter,
	startOfWeek,
	startOfYear,
	subMonths,
	subQuarters,
	subWeeks,
} from 'date-fns';
import { findIndex, merge } from 'lodash';

import { AppModule } from '@app/app.module';
import { BASE_COMMUNICATION_OPTIONS } from './constants';
import { ConfirmationComponent } from '@shared-components/confirmation-component/confirmation.component';
import { ExtendedCalendarView } from '@beego/ngx-calendar';
import { ISharedCreateOrUpdateClient } from '@shared-models/client.model';
import { PopoverController } from '@ionic/angular';
import { Segment } from '@beego/ngx-segment';
import { TranslateService } from '@ngx-translate/core';
import { nlBE } from 'date-fns/locale';

/**
 * A helper function to encode the data query that is passed to the query params of the api url
 * @param query The {@link IDataQuery} data query
 * @returns An encoded string to include in the URL
 */
export const encodeQueryUrl = (query: IDataQuery): string => encodeURIComponent(JSON.stringify(query));

/**
 * A helper function to merge or add a value in an Array
 * @param array The Array to merge or add a value in
 * @param _value The value to merge or add in the Array
 * @param property The property to use in the predicate (Defaults to 'id')
 * @returns The array with merged or added value
 */
export const mergeOrAddArrayValue = <Model>(
	array: Array<Model>,
	_value: Model,
	property: string = 'id'
): Array<Model> => {
	if (array) {
		const index = findIndex(array, (value) => value[property] === _value[property]);
		if (index !== -1) {
			array[index] = merge(array[index], _value);
		} else array.push(_value);
		return array;
	} else return [_value];
};

/**
 * A helper function to format a {@link IAddress} object into a string
 * @param address The address that needs to be formatted into a string
 * @returns The formatted address as string
 */
export const getAddressString = (address: IAddress | null): string =>
	address && address.street
		? `${address.street} ${address.housenumber}${address.floor ? ` ${address.floor}` : ''}, ${address.zipcode} ${
				address.city
			}`
		: null;

/**
 * A helper function that handles the manual change detection and validation when using the phoneOrMobile validator
 * @param _clientForm A FormGroup instance of a client
 */
export const setPhoneOrMobileError = (_clientForm: IFormGroup<ISharedCreateOrUpdateClient>): void => {
	setTimeout(() => {
		const mobile = _clientForm.get('mobile');
		const phone = _clientForm.get('phone');
		if (_clientForm.errors?.phoneOrMobile) {
			mobile.setErrors({ phoneOrMobile: _clientForm.errors.phoneOrMobile });
			phone.setErrors({ phoneOrMobile: _clientForm.errors.phoneOrMobile });
		}
		const setError = () => {
			if (_clientForm.errors?.phoneOrMobile) {
				mobile.setErrors({ phoneOrMobile: _clientForm.errors.phoneOrMobile });
				phone.setErrors({ phoneOrMobile: _clientForm.errors.phoneOrMobile });
			} else {
				delete mobile.errors?.phoneOrMobile;
				delete phone.errors?.phoneOrMobile;
				mobile.updateValueAndValidity({ emitEvent: false });
				phone.updateValueAndValidity({ emitEvent: false });
			}
		};
		mobile.valueChanges.subscribe(() => setTimeout(() => setError(), 1));
		phone.valueChanges.subscribe(() => setTimeout(() => setError(), 1));
	}, 1);
};

/**
 * A helper function to check whether or not a form is valid
 * Marks the entire formgroup as touched to show possible validation errors
 * @param formGroup The formgroup to check
 * @returns whether or not a form is valid
 */
export const formIsValid = (formGroup: IFormGroup): boolean => {
	markFormGroupTouched(formGroup);
	scrollFirstValidationErrorIntoView();
	return formGroup.valid;
};

/**
 * A helper method to scroll the first input into view that has a validation error
 *
 *
 */
export const scrollFirstValidationErrorIntoView = (): void => {
	if (
		document.querySelector(
			'ion-checkbox.ng-invalid, input.ng-invalid, form-select.ng-invalid, form-textarea.ng-invalid, form-input.ng-invalid,  form-number.ng-invalid, form-date.ng-invalid'
		)
	) {
		document
			.querySelector(
				'ion-checkbox.ng-invalid, input.ng-invalid, form-select.ng-invalid, form-textarea.ng-invalid, form-input.ng-invalid,  form-number.ng-invalid, form-date.ng-invalid'
			)
			.scrollIntoView({ behavior: 'smooth' });
	}
};

/**
 * Marks all controls in a form group as touched
 * This is relevant to show the validation errors
 * @param formGroup - The form group to touch
 */
export const markFormGroupTouched = (formGroup: UntypedFormGroup | AbstractControl): void => {
	if (formGroup) {
		Object.values((formGroup as any).controls).forEach((control: UntypedFormGroup) => {
			control.markAsTouched();
			if (control.controls) {
				markFormGroupTouched(control);
			}
		});
	}
};

/**
 * Moves an item in a FormArray to another position.
 * @param formArray FormArray instance in which to move the item.
 * @param fromIndex Starting index of the item.
 * @param toIndex Index to which he item should be moved.
 */
export const moveItemInFormArray = (formArray: UntypedFormArray, fromIndex: number, toIndex: number): void => {
	const clamp = (value: number, max: number): number => Math.max(0, Math.min(max, value));
	const from = clamp(fromIndex, formArray.length - 1);
	const to = clamp(toIndex, formArray.length - 1);

	if (from === to) {
		return;
	}

	const previous = formArray.at(from);
	const current = formArray.at(to);
	formArray.setControl(to, previous);
	formArray.setControl(from, current);
};

/**
 * A helper function to format a {@link ICommunicationOptions} object into a {@link ICommunication} object
 *
 * commonly used in Angular Reactive Forms
 * @param _communicationOptions An array of {@link ICommunicationOptions} that needs to be formatted into a string
 * @returns The formatted {@link ICommunication} object
 */
export const formatCommunicationOptions = (
	_communicationOptions: Array<ICommunicationOptions> = []
): ICommunication => {
	let options = _communicationOptions;
	if (!Array.isArray(_communicationOptions)) {
		options = [];
	}
	const communication = {};
	BASE_COMMUNICATION_OPTIONS.forEach((option) => {
		communication[option.key] = options?.find((selectedOption) => selectedOption.key === option.key) ? true : false;
	});
	return communication as ICommunication;
};

/**
 * A helper method to translate the name of segments
 * @param segments The segments
 * @returns The translated segments
 */
export const translateSegments = (segments: Array<Segment>): Array<Segment> => {
	return segments.map((segment) => translateSegment(segment));
};

/**
 * A helper method to translate the name of a segment
 * @param segment The segment
 * @returns The translated segment
 */
export const translateSegment = (segment: Segment): Segment => {
	const translateService: TranslateService = AppModule.injector.get(TranslateService);
	typeof segment === 'string'
		? (segment = translateService.instant(`SEGMENTS.${segment}`))
		: (segment.name = translateService.instant(`SEGMENTS.${segment.name}`));

	return segment;
};

/**
 * A helper method to translate a key into a string
 * @param key The translation key
 * @param params The translation params
 * @returns The translated string
 */
export const translate = (key: string, params?: Object): string => {
	const translateService: TranslateService = AppModule.injector.get(TranslateService);
	return translateService.instant(key, params);
};

/**
 * A helper method to get the name of a segment as string
 * @param segment The segment to get the name of
 * @returns {string} The segment name
 */
export const getSegmentName = (segment: Segment): string => (typeof segment === 'string' ? segment : segment?.name);

/**
 * Show the confirmation component to confirm an action (ex. deleting something)
 * @param messageKey The translation key for the action
 * @returns Whether the action is confirmed or not
 */
export const actionIsConfirmed = (messageKey: string): Promise<boolean> => {
	return new Promise(async (resolve) => {
		const popover = await AppModule.injector.get(PopoverController).create({
			component: ConfirmationComponent,
			componentProps: { message: messageKey },
		});
		void popover
			.onDidDismiss()
			.then((result) => {
				if (result.data !== undefined) {
					resolve(result.data);
				} else resolve(false);
			})
			.catch();
		await popover.present();
	});
};

/**
 * @static
 */
export class DateHelper {
	/**
	 * A function that returns a {@link IDateRange} object of the previous week
	 * @returns The {@link IDateRange} object
	 */
	public static getPreviousWeek(): IDateRange {
		const lastMonday: Date = subWeeks(setDay(new Date(), 1), 1);
		const lastSunday: Date = endOfWeek(lastMonday, { weekStartsOn: 1 });
		return { beginDate: format(lastMonday, 'yyyy-MM-dd'), endDate: format(lastSunday, 'yyyy-MM-dd') };
	}

	/**
	 * A function that returns a {@link IDateRange} object of the next 4 weeks, including this week
	 * @returns The {@link IDateRange} object
	 */
	public static getNext4Weeks(): IDateRange {
		const currentWeekMonday: Date = setDay(new Date(), 1);
		const sundayIn4Weeks: Date = addDays(currentWeekMonday, 6 + 3 * 7);
		return { beginDate: format(currentWeekMonday, 'yyyy-MM-dd'), endDate: format(sundayIn4Weeks, 'yyyy-MM-dd') };
	}

	/**
	 * A function that returns a {@link IDateRange} object of the future 4 weeks, not including this week
	 * @returns The {@link IDateRange} object
	 */
	public static getFuture4Weeks(): IDateRange {
		return {
			beginDate: format(endOfWeek(endOfDay(new Date()), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
			endDate: format(addWeeks(endOfWeek(endOfDay(new Date()), { weekStartsOn: 1 }), 4), 'yyyy-MM-dd'),
		};
	}

	/**
	 * A function that returns a {@link IDateRange} object of the previous month
	 * @returns The {@link IDateRange} object
	 */
	public static getPreviousMonth(): IDateRange & IDateRangeV2 {
		const beginOfLastMonth: Date = startOfMonth(subMonths(new Date(), 1));
		const endOfLastMonth: Date = endOfMonth(beginOfLastMonth);
		return {
			beginDate: format(beginOfLastMonth, 'yyyy-MM-dd'),
			startDate: format(beginOfLastMonth, 'yyyy-MM-dd'),
			endDate: format(endOfLastMonth, 'yyyy-MM-dd'),
		};
	}

	/**
	 * A function that returns a {@link IDateRange} object of the current month
	 * @returns The {@link IDateRange} object
	 */
	public static getCurrentMonth(): IDateRange {
		const beginOfCurrentMonth: Date = startOfMonth(new Date());
		const endOfCurrentMonth: Date = endOfMonth(beginOfCurrentMonth);
		return {
			beginDate: format(beginOfCurrentMonth, 'yyyy-MM-dd'),
			endDate: format(endOfCurrentMonth, 'yyyy-MM-dd'),
		};
	}

	/**
	 * A function that returns a {@link IDateRange} object of the current year
	 * @returns The {@link IDateRange} object
	 */
	public static getCurrentYear(): IDateRange {
		const beginOfCurrentYear: Date = startOfYear(new Date());
		const endOfCurrentYear: Date = endOfYear(beginOfCurrentYear);
		return {
			beginDate: format(beginOfCurrentYear, 'yyyy-MM-dd'),
			endDate: format(endOfCurrentYear, 'yyyy-MM-dd'),
		};
	}

	/**
	 * A function that returns a {@link IDateRange} object of the previous quarter
	 * @returns The {@link IDateRange} object
	 */
	public static getPreviousQuarter(): IDateRange {
		const beginOfLastQuarter: Date = startOfQuarter(subQuarters(new Date(), 1));
		const endOfLastQuarter: Date = endOfQuarter(beginOfLastQuarter);
		return { beginDate: format(beginOfLastQuarter, 'yyyy-MM-dd'), endDate: format(endOfLastQuarter, 'yyyy-MM-dd') };
	}

	/**
	 * A helper function to format a {@link IDateRange} object into a string
	 * @param _dateRange The date range that needs to be formatted into a string
	 * @returns The formatted date range as string
	 */
	public static getDateRangeString(_dateRange: IDateRange): string {
		return `${format(new Date(_dateRange.beginDate), 'd/M/yyyy')} - ${format(
			new Date(_dateRange.endDate),
			'd/M/yyyy'
		)}`;
	}

	/**
	 * A helper function to format a date parameter that could be a string but needs to be a Date
	 * @param _date The date as string or date
	 * @returns The formatted date as Date
	 */
	public static parseDate(_date: string | Date): Date {
		if (typeof _date === 'string') {
			return parseISO(_date as string);
		}
		return _date;
	}

	/**
	 * A helper function to format a date parameter that could be a string but needs to be a Date
	 * @param _date The date as string or date
	 * @param _time The time as string
	 * @returns The formatted date as Date
	 */
	public static generateDate(_date: string, _time: string): Date {
		const date = new Date(_date);
		date.setHours(parseInt(_time.slice(0, 2)), parseInt(_time.slice(3, 5)), parseInt(_time.slice(6, 8)));
		return date;
	}

	/**
	 * A helper function that gets the daterange of a date based on the view of the calendar
	 * @param _viewDate The current date
	 * @param _view The current view
	 * @returns The correct daterange for that date and view
	 */
	public static getDateRange(_viewDate: Date, _view: ExtendedCalendarView): IDateRange {
		switch (_view) {
			case ExtendedCalendarView.Month:
				return {
					beginDate: format(startOfMonth(_viewDate), 'yyyy-MM-dd'),
					endDate: format(endOfMonth(_viewDate), 'yyyy-MM-dd'),
				};
			case ExtendedCalendarView.Week:
			case ExtendedCalendarView.WorkWeek:
				return {
					beginDate: format(startOfWeek(_viewDate, { weekStartsOn: 1 }), 'yyyy-MM-dd'),
					endDate: format(endOfWeek(_viewDate, { weekStartsOn: 1 }), 'yyyy-MM-dd'),
				};
			case ExtendedCalendarView.Day:
				return {
					beginDate: format(_viewDate, 'yyyy-MM-dd'),
					endDate: format(_viewDate, 'yyyy-MM-dd'),
				};
		}
	}

	/**
	 * Get the date and date formatted for the event intervals (ex. 1st day of the month and 1st Friday of the month)
	 * @param date The date to get the formatted data for
	 * @returns the date and day as readable text
	 */
	public static getDateAndDayForEventInterval(date: Date | string): { date: string; day: string } {
		const startsAt = new Date(date);
		return {
			date: format(startsAt, 'do', { weekStartsOn: 1, locale: nlBE }),
			day: `${DateHelper.getOccurrenceOfWeekdayInMonthAsString(startsAt)} ${format(startsAt, 'eeee', {
				weekStartsOn: 1,
				locale: nlBE,
			})}`,
		};
	}

	/**
	 * Get the occurrence of a weekday in a month (ex. the 2nd monday of the month)
	 * formatted as string using the application locale
	 * @param date The date to get the occurrence for
	 * @returns The occurrence as formatted string
	 */
	public static getOccurrenceOfWeekdayInMonthAsString(date: Date): string {
		const dayNumber = getDate(date);
		const occurrence = Math.floor(dayNumber / 7 - 0.00001) + 1;
		return format(set(new Date(), { date: occurrence }), 'do', { weekStartsOn: 1, locale: nlBE });
	}
}
